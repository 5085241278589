import { render, staticRenderFns } from "./VendorsExport.vue?vue&type=template&id=bbd3a22c&scoped=true"
import script from "./VendorsExport.vue?vue&type=script&lang=js"
export * from "./VendorsExport.vue?vue&type=script&lang=js"
import style0 from "./VendorsExport.vue?vue&type=style&index=0&id=bbd3a22c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbd3a22c",
  null
  
)

export default component.exports